import _ from "lodash";

export default {
  data() {
    return {
      name: "",
      perPage: 10,
      offset: 0,
      showList: [10, 25, 50, 100],
    };
  },
  computed: {
    sortedItems() {
      return this.items.slice(
        parseInt(this.offset),
        parseInt(this.perPage) + parseInt(this.offset)
      );
    },
    totalItems() {
      return this.items.length;
    },
    end() {
      const end = this.offset + this.perPage;
      if (end > this.totalItems) {
        return this.totalItems;
      }
      return end;
    },
    nbPages() {
      return this.items.length / this.perPage;
    },
  },
  methods: {
    getElement(item, val) {
      return _.get(item, val);
    },
    previousPage() {
      if (this.offset - this.perPage >= 0) {
        this.offset -= this.perPage;
      } else {
        return 0;
      }
    },
    nextPage() {
      this.offset += this.perPage;
    },
    setPage(index) {
      this.offset = index * this.perPage;
    },
    sortItems(index, sortBy) {
      const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

      if (this.headers[index].sort === "asc") {

        this.headers[index].sort = "desc";
        this.items.sort(function (a, b) {

          var x = _.get(a, sortBy);
          var y = _.get(b, sortBy);

          if(_.isNull(x)) x = "";
          if(_.isNull(y)) y = "";

          if ((typeof x === 'string' || x instanceof String) && dateRegex.test(x)) {
            x = (new Date(x.split("/")[1]+"/"+x.split("/")[0]+"/"+x.split("/")[2]).valueOf());
            y = (new Date(y.split("/")[1]+"/"+y.split("/")[0]+"/"+y.split("/")[2]).valueOf());
          }

            if ((typeof x === 'array' || x instanceof Array)) {
                if(x[0]){ x = x[0].name } else { x = "" }
                if(y[0]){ y = y[0].name } else { y = "" }
            }

            if(y != null && x != null && Number.isInteger(x) && Number.isInteger(y)) {
            return (x < y ? 1 : -1) ;
          }

          return x.toString().toLowerCase().localeCompare(y.toString().toLowerCase());
        });
      } else {
        this.headers[index].sort = "asc";
        this.items.sort(function (a, b) {

          var x = _.get(a, sortBy);
          var y = _.get(b, sortBy);

          if(_.isNull(x)) x = "";
          if(_.isNull(y)) y = "";

          if ((typeof x === 'string' || x instanceof String) && dateRegex.test(x)) {
            x = (new Date(x.split("/")[1]+"/"+x.split("/")[0]+"/"+x.split("/")[2]).valueOf());
            y = (new Date(y.split("/")[1]+"/"+y.split("/")[0]+"/"+y.split("/")[2]).valueOf());
          }

            if ((typeof x === 'array' || x instanceof Array)) {
                if(x[0]){ x = x[0].name } else { x = "" }
                if(y[0]){ y = y[0].name } else { y = "" }
            }

          if (x != null && y != null) {
            if (Number.isInteger(x) && Number.isInteger(y)) {
              return x > y ? 1 : -1;
            }
            return y
              .toString()
              .toLowerCase()
              .localeCompare(x.toString().toLowerCase());
          } else if (x != null) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    },
  },
};
